/* Nav.css */
.navbar-nav {
  text-align: center;
  width: 100%;
}

.nav-item {
  width: 100%;
}
.navbar-brand img {
  height: 20%;
  width: 20%;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #ddd;
}

.company-logo{
  font-size: 15px;
}

@media (min-width: 992px) {
  .navbar-nav {
    width: auto;
    text-align: left;
  }

  .nav-item {
    width: auto;
  }

  .nav-link {
    border-bottom: none;
  }
  .navbar-brand img {
    height: 20%;
    padding-left: 20px;
    width: 20%;
  }
}
.slide-in {
  transition: max-height 0.3s ease-out;
}

@media (min-width: 992px) {
  .slide-in {
    max-height: none !important;
  }
}

