.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.carousel-item {
  position: relative;
}

.carousel-inner {
  position: relative;
 
}

.img-landing {
  height: 400px;
}
.rotating-text::before {
  content: attr(data-words);
  animation: textRotate 12s infinite;
}
.carousel-inner {
  background-color: #545353;
  background-size: cover;
  background-position: center;
}

@media screen and (min-width: 1000px) {
  .img-landing {
    height: 500px;
    width: 500px;
  }
}

@keyframes textRotate {
  0%,
  25% {
    content: attr(data-words);
  }
  26%,
  50% {
    content: attr(data-words);
  }
  51%,
  75% {
    content: attr(data-words);
  }
  76%,
  100% {
    content: attr(data-words);
  }
}

.sliding-button {
  position: relative;
  transition: all 0.3s ease-in-out;
  background-color: rgba(52, 112, 231, 0.448);
  color: white;
  border-radius: 20px;
}

.sliding-button:hover {
  transform: translateX(10px);
}

/*The packages*/

/* Package.css */
.horizontal-scroll {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 1rem; 
}

.horizontal-scroll::-webkit-scrollbar {
  display: none; 
}

.horizontal-scroll > .card {
  flex: 0 0 auto; 
  width: 80%; 
  max-width: 300px; 
}


/* Home.css */

.chat-icon-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  border-radius: 50%;
  padding: 10px;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.chat-icon {
  color: white;
}

.chat-icon-container:hover {
  background-color: #0056b3;
}

.whatsapp-float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;

  color: #25d366;
  border-radius: 50%;
  text-align: center;
  font-size: 2rem;
  box-shadow: 0px 4px 10px rgba(25, 155, 21, 0.15);
  z-index: 100;
  transition: background-color 0.3s, transform 0.3s;
}

.whatsapp-float:hover {
  background-color: #25d366;
  transform: scale(1.1);
}

@media (max-width: 767.98px) {
  .whatsapp-float {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    bottom: 15px;
    right: 15px;
  }
}
